<template>
	<div>
		<div class="osahan-signin">
			<div class=" p-3">
				<router-link :to="{ name: 'home' }">
					<img
						class="w-screen object-cover"
						:src="`${$root.env.VUE_APP_PUBLIC_PATH}img/logo-1.svg`"
					/>
				</router-link>
				<form class="mt-8">
					<div class="form-group  pr-3 pl-3">
						<label for="input_email1">Email</label>
						<input
							placeholder="Enter Email"
							type="email"
							v-model="form.email"
							class="form-control"
							id="input_email1"
							aria-describedby="emailHelp"
						/>
						<span
							class="text-danger pr-2"
							v-if="$root.errors.email"
						>
							{{ $root.errors.email.join(',') }}
						</span>
					</div>
					<div class="form-group pr-3 pl-3">
						<label for="input_password1">Password</label>
						<input
							placeholder="Enter Password"
							type="password"
							v-model="form.password"
							class="form-control"
							id="input_password1"
						/>
						<span
							class="text-danger pr-2"
							v-if="$root.errors.password"
						>
							{{ $root.errors.password.join(',') }}
						</span>
					</div>
					<button
						@click="authenticate"
						type="button"
						class="btn bg-magenta text-white btn-lg rounded btn-block mt-8  pr-3 pl-3"
					>
						L O G I N
					</button>
					<div class="flex -mx-2">
						<button
							type="button"
							class="btn bg-danger text-white m-2 rounded btn-block"
							@click="AuthProvider('google')"
						>
							<span class="text-xl"
								><i class="icofont-google-plus"></i
							></span>
							Google
						</button>
						<button
							type="button"
							class="btn bg-info text-white m-2 rounded btn-block"
							@click="AuthProvider('facebook')"
						>
							<span class="text-xl"
								><i class="icofont-facebook"></i
							></span>
							Facebook
						</button>
					</div>
				</form>
			</div>
		</div>
		<div class="osahan-fotter">
			<router-link
				:to="{ name: 'auth-signup' }"
				class="btn btn-block btn-lg "
			>
				You do not have an account ? Register
			</router-link>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ApiAuthUser } from '@/tools/apiService.js';

export default {
	data: () => ({
		form: {}
	}),
	computed: mapGetters({
		user: 'auth/user'
	}),
	mounted() {},
	methods: {
		...mapActions({
			saveToken: 'auth/saveToken',
			fetchUser: 'auth/fetchUser'
		}),
		authenticate: function() {
			this.saveToken({ token: null });
			return ApiAuthUser(this.form).then(response => {
				this.saveToken({
					token: response.data.token
				});
				this.$router.push({
					name: 'user-commands'
				});
				return true;
			});
		},
		AuthProvider(provider) {
			console.log("opening ", provider)
			this.$auth
				.authenticate(provider)
				.then(async response => {
					console.log(response);
				})
				.catch(async error => {
					console.log(error);
				})
				.then(async () => {
					let token = localStorage.getItem('token');
					console.log('saved token', token);
					if (token) {
						await this.saveToken({ token });
						setTimeout(async () => {
							if (await this.fetchUser()) {
								this.$router.push({
									name: 'user-commands'
								});
							}
						}, 1000);
					}
				});
		}
	}
};
</script>

<style>
.fixed-bottom {
	border-radius: 0px;
	box-shadow: none;
	height: 59px;
}
</style>
